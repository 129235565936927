<template>
	<v-card class="forgot-password-view">
		<v-card-title>
			Forgot Password
		</v-card-title>
		<v-card-text>
			<p>
				Enter your email below to have a link emailed to you with
				instructions on how to reset your password.
			</p>
			<form v-on:submit.prevent="onSubmit">
				<ui-label>
					<ui-input v-model="email" placeholder="Email" />
				</ui-label>
				<ui-label>
					<Roundup-Button
						type="submit"
						color="primary"
						:loading="isLoading"
						class="mt-2"
						>Reset Password
					</Roundup-Button>
				</ui-label>
				<div class="forgot-password">
					<router-link :to="{ name: 'login' }"
						>Back To Login</router-link
					>
				</div>
			</form>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapActions } from 'vuex'
import Screen from '../../ui/Screen'
import UiButton from '../../ui/Button'
import UiInput from '../../ui/Input'
import UiLabel from '../../ui/Label'

export default {
	name: 'forgot-password',
	components: { Screen, UiButton, UiInput, UiLabel },
	data() {
		return {
			isLoading: false,
			email: ''
		}
	},
	methods: {
		...mapActions(['forgotPassword']),
		onSubmit() {
			this.isLoading = true
			this.forgotPassword(this.email)
				.then(response => {
					this.isLoading = false
					this.setAlert({
						alertText: response.message,
						showAlert: true
					})
				})
				.catch(({ message }) => {
					this.isLoading = false

					this.setAlert({
						alertText: message,
						showAlert: true,
						alertColor: 'error'
					})
				})
		}
	}
}
</script>

<style lang="scss">
.forgot-password-view {
	text-align: left;
	min-width: 300px;
	max-width: 460px !important;
	margin: auto;
	padding: 10px !important;
}
.screen.auth-view {
	.forgot-password {
		font-size: 13px;
	}
}
</style>
